<template>
  <div class="home">
     <topNav :curid="4" :showCeng="showFlag"  @cancal="cancal"></topNav>
    <div class="pricebox">

    </div>  
    <div class="priceTable">
    <table border="1" cellspacing="0" cellpadding="0">
      <tr class="pricetrbg">
        <td width="28%" style="text-align:right"></td>
        <td width="24%">基础版</td>
        <td width="24%">专业版</td>
        <td width="24%">旗舰版</td>
      </tr>
      <tr class="trtxt">
        <td>功能特权</td>
        <td class="trtxt">适合个人、小规模团队，满足开团、订单交易等基础需求；或作为小微供应商进行私域渠道的开团管理、商品管理和订单管理。</td>
        <td class="trtxt">适合专业的团长团队，快速、高效的开团，管理多家供应商的货源，代发订单；或具备一定规模的供应商招募私域渠道的团长进行大量分销，规模化管理订单。</td>
        <td class="trtxt">适合大型团长或品牌供应商，使用平台工具高效管理超百个渠道、供应商的规模化订单，以及财务结算、售后。</td>
      </tr>
      <tr>
        <td>基础定价</td>
        <td><span class="">￥3998/年</span></td>
        <td><span class="">￥18000/年</span></td>
        <td><span class="">联系销售</span></td>
      </tr>
      <tr>
        <td><span class="nums">限时优惠</span></td>
        <td><span class="nums">￥1999/年<br/>（5折优惠）</span><br/>
        <div class="curbtn" @click="changeLogin">免费体验</div>
        </td>
        <td><span class="nums">￥8888/年<br/>（5折优惠）</span><br/>
        <div class="curbtn" @click="changeLogin">免费体验</div></td>
        <td><span class="nums">联系销售 <br/> </span>
        
        <br/><div class="curbtn" @click="changeLogin">免费体验</div></td>
      </tr>
       <tr>
        <td>开团大厅</td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>分销开团</td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>发布新团</td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>商品管理</td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>商品数量</td>
        <td><span class="">100</span></td>
        <td><span class="">5000</span></td>
        <td><span class="">不限</span></td>
      </tr>
      <tr>
        <td>订单上传</td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>订单管理</td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>订单数量</td>
        <td><span class="orders">100单/日</span></td>
        <td><span class="orders">5000单/日</span></td>
        <td><span class="orders">不限</span></td>
      </tr>
      <tr>
        <td>导出模板</td>
        <td><span class="el-icon-error"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>机器人数量</td>
         <td><span class="el-icon-error"></span></td>
        <td><span class="">1</span></td>
        <td><span class="">不限</span></td>
      </tr>
      <tr>
        <td>自动收单</td>
        <td><span class="el-icon-error"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>自动收款</td>
        <td><span class="el-icon-error"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>自动返单</td>
        <td><span class="el-icon-error"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>子账号管理</td>
        <td><span class="el-icon-error"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>数据分析</td>
        <td><span class="el-icon-error"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
       <tr>
        <td>操作日志</td>
        <td><span class="el-icon-error"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
       <tr class="pricetrbg">
        <td colspan="4">贴心服务</td>
      </tr>
      <tr>
        <td>在线客服（8:00-22:00）</td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>免费培训课程</td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>
      <tr>
        <td>免费在线指导</td>
        <td><span class="el-icon-error"></span></td>
        <td><span class="el-icon-success"></span></td>
        <td><span class="el-icon-success"></span></td>
      </tr>

    </table>

    </div>
       <div class="footer">Copyright@2018-2021 kaituanyi.com</div>
  </div>
</template>

<script>
import topNav from "../components/topNav.vue";
export default {
  name: 'Price',
  components: { topNav },
  data() {
    return {
      showFlag: false
    }
  },
  methods: {
    changeLogin(){
      this.showFlag = true
    },
    cancal(val){
      this.showFlag = val
    }
  }
}
</script>

<style scoped>
.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
  min-height: 680px;
  overflow: auto;
  background-color: #fff;
}
.home /deep/ .el-dialog__header{
  border-bottom: none;
}
.home /deep/ .el-dialog__body{
  padding: 0;
  margin-top: -30px;
}
.home .el-dialog__body{
  padding: 0;
}

.pricebox{
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg,#06c58f,#07c160);
}
.priceTable{
  width: 1000px;
  display: block;
  margin: 0 auto;
  padding: 20px 0 100px;
}
.curbtn {
  background-color: #ff7200;
  color: #fff;
  border-radius: 6px;
  font-weight: bold;
  display: inline-block;
  height: 32px;
  padding: 0 20px;
  text-align: center;
  font-size: 13px;
  margin: 10px 0;
  line-height: 32px;
  cursor: pointer;
}

.priceTable table{ border-collapse:collapse; border:solid 1px #fdf0e0; }
.priceTable table td{  border:solid 1px #fdf0e0; padding:15px; text-align:center; color:#818999;line-height:28px; font-size:15px; font-weight: 600;}
.priceTable table span.el-icon-success{ color:#06c58f; font-size:24px;}
.priceTable table span.el-icon-error{ color:#ccc; font-size:24px;}
.priceTable table td.trtxt{ color: #818999; text-align: left;}
.priceTable table span.nums{ color:#ff0000;}
.priceTable table tr.pricetrbg td{
  background-color: hsla(39,81%,90%,.5);
  color: #754815;
  font-size: 24px;
  padding: 20px;
}
</style>
